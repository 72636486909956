import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { ShowcaseOverview } from 'src/containers/ShowcaseOverview';
import Layout from '../layouts/default';
export const _frontmatter = {
  "title": "Community Showcase",
  "alternativeHeader": true
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  is404,
  children,
  ...props
}) => {
  return is404 ? children : <Layout children={children} {...props} />;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ShowcaseOverview mdxType="ShowcaseOverview" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      