import React from 'react';

import { SEO } from 'src/containers/SEO';
import NotFoundPage from '../404';
import IndexPage from '../showcase.mdx';

export default function FourOhFourPage({ data }) {
  return (
    <NotFoundPage
      subtitle="Sorry, this showcase no longer exists. Below are some more showcases you might be interested in"
      indexUrl="/showcase/"
    >
      <SEO description="Sorry, this showcase no longer exists." />
      <IndexPage data={data} is404 />
    </NotFoundPage>
  );
}
